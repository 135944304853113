/* style={{
    width:"99%",
    height:"25px",
    borderRadius:"0.25rem",
    paddingLeft:"10px",
    border:"1px solid black",
    &:focus {
        outline: none;
        border-color: transparent;
      }
    
   }} */

   .table-searchbar{
    width: 99%;
    height: 30px;
    border-radius: 0.25rem;
    padding-left: 10px;
    border: 1px solid rgb(145, 145, 145);
    font-size: 1rem;
    box-shadow: none;
    &:focus{
       
        outline: none;
        border-color: transparent;
        border: 1px solid rgb(175, 175, 175);
        box-shadow: none; /* Sometimes the glow is due to box-shadow */
    }
    &::placeholder{
        color: rgb(89, 88, 88);font-family: Gilroy;
    }
   }