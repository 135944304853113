.filterOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2em;
}

.chipsStyle {
  font-size: 1rem !important;
  padding: 1em !important;
  margin: 0.5em !important;
  border: 1px solid #582ff1 !important;
 
}

.chipsStyle:hover {
  background: #582ff1 !important;
  color: white !important;
}

.small-btns{
  font-size: 0.8rem !important;
  letter-spacing: 0.02857em !important;
  min-width: 64px !important;
  padding: 8px 10px !important;
 
}


.orange-shade{
  background: #eb8900 !important;
}

.app-title{

  /* background: linear-gradient(to right, #0048e8, #0048e8,#f08c00, #f08c00); */
  /* background: linear-gradient(90deg, #0048e8 40%, #00a6ff 10%, #f08c00 40%, #ffb347); */
  /* background: linear-gradient(90deg, #0048e8, #3ecf8e, #f08c00, #d81159); */
  /* background: linear-gradient(90deg, #0048e8, #f08c00, #3dd6fa, #ff9c33); */
  background: linear-gradient(90deg, #582FF1, #582FF1, #8dff83, #8dff83);
 
  -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    animation: gradient-blink 3s infinite linear; /* Animation properties */
}

 

@media only screen and (max-width: 1250px) {
  .filterOptions {
    flex-direction: column;
    justify-content: left;
  }
  .found-filter {
    margin-top: 1em;
  }
}
